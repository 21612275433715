<template>
  <div>
    <Modal :value="visible"
           title="选择单位"
           footer-hide
           width="980"
           @on-visible-change="onVisibleChange">
      <div class="selectDealer">
        <div class="selectDealer-topbar">
          <div class="flex a-center">
            <Input v-model="searchValue"
                   placeholder="单位名称"
                   search
                   @on-search="onSearch"
                   style="width:280px" />
          </div>
          <Button @click.stop="onClickCreate">新建单位</Button>
        </div>
        <Table :height="500"
               border
               :columns="columns"
               :data="data"
               @on-row-click="onRowClick">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop="onClickEdit(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">编辑</Button>
          </template>
        </Table>
        <Page class="m-t-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
        <div class="selectDealer-btn">
          <Button type="primary"
                  class="m-r-25"
                  :disabled="!selectDealer"
                  @click.stop="onClickConfirm">确认</Button>
          <Button @click.stop="onClickCancle">取消</Button>
        </div>
      </div>
    </Modal>
    <DealerCreateModal v-model="createVisible"
                       :dealerId.sync="createDealerId"
                       @on-click-confirm="onClickConfirmDealer"></DealerCreateModal>
  </div>
</template>

<script>
import { getDealerList } from '../../services/dealerapi';
import DealerCreateModal from './DealerCreateModal';
export default {
  components: { DealerCreateModal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean
  },
  data () {
    return {
      searchValue: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      columns: [
        {
          title: ' ',
          width: 45,
          align: 'center',
          render: (h, params) => {
            return h('Radio', {
              props: {
                value: params.row.checked
              },
              on: {
                'on-change': () => {
                  this.onRowClick(params.row, params.index)
                }
              }
            });
          }
        },
        {
          title: '单位名称',
          key: 'unitName',
          tooltip: true,
          minWidth: 120,
        },
        {
          title: '负责人',
          key: 'userName',
          tooltip: true,
          width: 120
        },
        {
          title: '联系电话',
          key: 'phone',
          tooltip: true,
          width: 120
        },
        {
          title: '地址',
          key: 'location',
          tooltip: true,
          minWidth: 160
        },
        {
          title: '操作',
          slot: 'operation',
          width: 60,
          align: 'center'
        }
      ],
      data: [],
      selectDealer: null,

      createVisible: false,
      createDealerId: 0,
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getDealerList()
    },
    onSearch () {
      this.pageNum = 1
      this.getDealerList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getDealerList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getDealerList()
    },
    // 获取单位列表
    getDealerList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchValue) params.unitName = this.searchValue
      getDealerList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          item.checked = false
          if (item.dealerLinkmanList.length > 0) {
            item.userName = item.dealerLinkmanList[0].name
            item.phone = item.dealerLinkmanList[0].phone
          }
          let locationList = item.locationList
          if (locationList.length > 0) {
            let location
            locationList.forEach(items => {
              if (items.defaultType === 1) {
                location = items
              }
            })
            if (!location) location = locationList[0]
            item.location = location.prov + location.city + location.dist + location.address
          }
          return item
        })
      })
    },
    // 点击某一行
    onRowClick (row, index) {
      this.data.forEach(item => {
        this.$set(item, 'checked', false);
      });
      this.data[index].checked = true;
      this.selectDealer = row;
    },
    // 点击确定
    onClickConfirm () {
      this.$emit('change', false)
      this.$emit('on-click-confirm', this.selectDealer)
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
      this.$emit('on-click-cancle')
    },
    // 显示状态改变
    onVisibleChange (boo) {
      if (!boo) this.onClickCancle()
    },
    // 点击新建单位
    onClickCreate () {
      this.createDealerId = 0
      this.createVisible = true
    },
    // 点击编辑
    onClickEdit (row) {
      this.createDealerId = row.dealerId
      this.createVisible = true
    },
    onClickConfirmDealer () {
      this.createVisible = false
      this.getDealerList()
    }
  },
}
</script>

<style lang='scss' scoped>
.selectDealer {
  .selectDealer-topbar {
    padding: 4px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ::v-deep {
    .ivu-table-cell {
      padding: 0 8px;
    }
  }
  .selectDealer-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
</style>