<template>
  <div>
    <Modal :value="visible"
           :title="dealerId?'编辑':'新增'+'单位'"
           footer-hide
           @on-visible-change="onModalClose"
           width="720">
      <div class="createDealer">
        <ul class="createDealer-form">
          <li class="createDealer-li">
            <p><span style="color:#D0021B">*</span>单位:</p>
            <Input v-model="name"
                   placeholder="单位名称"
                   maxlength="20"
                   style="width:220px" />
          </li>
          <li class="createDealer-li">
            <p><span style="color:#D0021B">*</span>负责人:</p>
            <Input v-model="admin"
                   placeholder="负责人"
                   maxlength="8"
                   style="width:220px" />
          </li>
          <li class="createDealer-li">
            <p><span style="color:#D0021B">*</span>联系电话:</p>
            <Input v-model="phone"
                   placeholder="联系电话"
                   maxlength="11"
                   @on-keyup="phone=formatNumber(phone)"
                   style="width:220px" />
          </li>
          <li class="createDealer-li">
            <p><span style="color:#D0021B">*</span>地址:</p>
            <div class="flex-1">
              <div class="createDealer-location"
                   v-for="(item,index) in locationList"
                   :key="index">
                <div class="createDealer-location-left">
                  <AddressChooseComponent :width="112"
                                          :key="index"
                                          :addressinfo="item.location"></AddressChooseComponent>
                  <Input v-model="item.address"
                         placeholder="详细地址"
                         maxlength="120"
                         style="width:100%;margin-top:15px" />
                </div>
                <div class="createDealer-location-right">
                  <Radio v-model="item.isDefault"
                         @on-change="()=>{onChangeDefaultLocation(item)}"
                         class="p-t-5 p-b-5">默认地址</Radio>
                  <div class="text-right m-t-20">
                    <Icon custom="i-icon icon-remove"
                          size="24"
                          color="#E04C53"
                          class="cursor-pointer"
                          @click.stop="locationList.splice(index,1)"
                          v-if="locationList.length>1"></Icon>
                    <Icon custom="i-icon icon-adds"
                          size="24"
                          color="#0652DD"
                          class="cursor-pointer m-l-8"
                          @click.stop="onClickAddLocation"></Icon>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="createDealer-li">
            <p>备注:</p>
            <Input v-model="remark"
                   type="textarea"
                   :rows="4"
                   :autosize="{maxRows: 4,minRows: 4}"
                   placeholder="备注信息"
                   style="width:100%" />
          </li>
          <li class="createDealer-li createDealer-pic">
            <p style="width:100%">单位照片:</p>
            <div class="createDealer-pic-upload"
                 @click.stop="uploadPic">
              <template v-if="coverList.length===0">
                <Icon custom="i-icon icon-upload_pic"
                      size="42"
                      class="m-b-5"></Icon>
                上传图片
              </template>
              <template v-else>
                <img :src="getDefaultCover(coverList).url">
              </template>
            </div>
          </li>
        </ul>
        <div class="createDealer-btn">
          <Button type="primary"
                  class="m-r-20"
                  @click.stop="onClickConfirm">确认</Button>
          <Button @click.stop="onClickCancle">取消</Button>
        </div>
      </div>
    </Modal>
    <Modal v-model="uploadPicVisible"
           title="上传图片"
           footer-hide
           width="560">
      <div class="uploadPic">
        <ImageListComponent uploadpath="/company/about/"
                            :data="imageList"
                            @upload-success="uploadCoverSuccess"></ImageListComponent>
        <div class="uploadPic-btn">
          <Button type="primary"
                  class="m-r-20"
                  @click.stop="onClickConfirmPic">确认</Button>
          <Button @click.stop="onClickCanclePic">取消</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createDealer, editDealer, getDealerDetail } from '../../services/dealerapi';
import AddressChooseComponent from '../common/AddressChooseComponent';
import ImageListComponent from '../common/ImageListComponent';
export default {
  components: { AddressChooseComponent, ImageListComponent },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    dealerId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:dealerId', 0)
        this.resetDealerInfo()
      }
    },
    dealerId (newVal) {
      if (newVal && this.visible) {
        this.getDealerDetail()
      } else {
        this.$emit('update:dealerId', 0)
        this.resetDealerInfo()
      }
    }
  },
  data () {
    return {
      libraryId: 0,
      name: '',
      admin: '',
      phone: '',
      locationList: [{
        location: {
          initialization: false,
          province: '',
          provName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: ''
        },
        address: '',
        isDefault: true
      }],
      remark: '',
      coverList: [],
      uploadPicVisible: false,
      imageList: []
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 只能输入数字
    formatNumber (str) {
      return str.replace(/[^\d]/g, '')
    },
    // 获取默认图
    getDefaultCover (arr) {
      for (const item of arr) {
        if (item.isDefault) {
          return item
        }
      }
      return arr[0]
    },
    onModalClose (status) {
      if (!status) {
        this.resetDealerInfo()
        this.$emit('change', false)
      }
    },
    // 上传照片
    uploadPic () {
      this.imageList = this.coverList.map(item => item)
      this.uploadPicVisible = true
    },
    // 上传图片成功
    uploadCoverSuccess (arr) {
      arr.forEach(item => {
        this.imageList.push({
          url: item.url,
          width: item.width,
          height: item.height,
          isDefault: this.imageList.length === 0
        })
      })
    },
    // 修改默认地址
    onChangeDefaultLocation (row) {
      this.locationList.forEach(item => {
        item.isDefault = false
      })
      row.isDefault = true
    },
    // 添加地址
    onClickAddLocation () {
      this.locationList.push({
        location: {
          initialization: false,
          province: '',
          provName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: ''
        },
        address: '',
        isDefault: false
      })
    },
    // 点击确定
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请填写单位名称')
      if (!this.admin) return this.$Message.info('请填写负责人')
      if (!this.phone) return this.$Message.info('请填写联系电话')
      for (const item of this.locationList) {
        if (!item.location.province || !item.location.city || !item.location.area || !item.address) return this.$Message.info('请填写地址')
      }
      let params = {
        libraryId: this.libraryId,
        unitName: this.name,
        linkManList: [{
          name: this.admin,
          phone: this.phone,
          isDefault: 1
        }],
        locationList: this.locationList.map(item => {
          return {
            defaultType: item.isDefault ? 1 : 0,
            provCode: item.location.province,
            prov: item.location.provName,
            cityCode: item.location.city,
            city: item.location.cityName,
            distCode: item.location.area,
            dist: item.location.areaName,
            address: item.address
          }
        }),
        extension: this.remark,
        coverList: this.coverList.map(item => {
          return {
            url: item.url,
            width: item.width,
            height: item.height,
            extension: JSON.stringify({ isDefault: item.isDefault })
          }
        })
      }
      if (this.dealerId) {
        editDealer(this.dealerId, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('修改成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        createDealer(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('新建成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 重置经销商信息
    resetDealerInfo () {
      this.name = ''
      this.admin = ''
      this.phone = ''
      this.locationList = [{
        location: {
          initialization: false,
          province: '',
          provName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: ''
        },
        address: '',
        isDefault: true
      }]
      this.remark = ''
      this.coverList = []
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
    },
    // 点击确定
    onClickConfirmPic () {
      this.coverList = this.imageList.map(item => item)
      this.uploadPicVisible = false
    },
    // 点击取消
    onClickCanclePic () {
      this.imageList = this.coverList.map(item => item)
      this.uploadPicVisible = false
    },
    // 获取单位详情
    getDealerDetail () {
      getDealerDetail(this.dealerId).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.libraryId = data.libraryId || 0
          this.name = data.unitName || ''
          if (data.dealerLinkmanList.length > 0) {
            this.admin = data.dealerLinkmanList[0].name || ''
            this.phone = data.dealerLinkmanList[0].phone || ''
          }
          this.locationList = data.locationList.map(item => {
            return {
              location: {
                initialization: true,
                province: item.provCode.toString(),
                provName: item.prov.toString(),
                city: item.cityCode.toString(),
                cityName: item.city.toString(),
                area: item.distCode.toString(),
                areaName: item.dist.toString()
              },
              address: item.address,
              isDefault: item.defaultType === 1
            }
          })
          this.remark = data.extension || ''
          this.coverList = data.coverList.map(item => {
            return {
              url: item.url,
              width: item.width,
              height: item.height,
              isDefault: item.extension ? JSON.parse(item.extension).isDefault : ''
            }
          })
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.createDealer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .createDealer-form {
    position: relative;
    .createDealer-li {
      display: flex;
      margin-top: 30px;
      p {
        width: 80px;
        font-size: 16px;
        line-height: 32px;
        text-align: right;
        margin-right: 10px;
        flex-shrink: 0;
      }
      .createDealer-location {
        display: flex;
        &:not(:first-child) {
          margin-top: 15px;
        }
        .createDealer-location-right {
          margin-left: 20px;
        }
      }
    }
    .createDealer-pic {
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      p {
        text-align: left;
      }
      .createDealer-pic-upload {
        width: 160px;
        height: 120px;
        border: 1px dashed rgba(0, 0, 0, 0.24);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .createDealer-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.uploadPic {
  padding-left: 20px;
  .uploadPic-title {
    font-size: 16px;
  }
  .uploadPic-btn {
    display: flex;
    justify-content: center;
  }
}
</style>