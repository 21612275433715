<template>
  <div class="main"
       ref="main">
    <div class="title">出库激活</div>
    <div class="content">
      <div class="form">
        <ul class="form-ul">
          <li class="form-li">
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>出库仓库:</span>
              </div>
              <div class="form-value">
                <Input v-model="storeName"
                       disabled
                       style="width:240px;padding-right: 32px;" />
                <span class="form-span"
                      @click.stop="selectStorage">选择</span>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>收货单位:</span>
              </div>
              <div class="form-value">
                <Input v-model="dealerName"
                       disabled
                       style="width:240px;padding-right: 32px;" />
                <span class="form-span"
                      @click.stop="selectDealer">选择</span>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>库管员:</span>
              </div>
              <div class="form-value">
                <Input v-model="adminName"
                       disabled
                       style="width:240px;padding-right: 32px;" />
                <span class="form-span"
                      @click.stop="selectStoragekeeper">选择</span>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B"></span>
                <span>激活时间:</span>
              </div>
              <div class="form-value">
                <DatePicker type="date"
                            :value="date"
                            placeholder="请选择激活时间"
                            :disabled="taskStatus===1"
                            @on-change="onChangeDate"
                            style="width:240px"></DatePicker>
              </div>
            </div>
          </li>
          <li class="form-li">
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>激活数量:</span>
              </div>
              <div class="form-value">
                <Input v-model="number"
                       disabled
                       style="width:240px"
                       class="big" />
              </div>
            </div>
            <ImportTxt :disabled="loading"
                       @import-start="importStart"
                       @import-success="importSuccess"
                       @import-error="importError">
              <Button type="primary"
                      class="form-btn"
                      :loading="loading">{{loading?'导入中...':'导入数据包'}}</Button>
            </ImportTxt>
            <Button type="primary"
                    class="form-btn"
                    :disabled="loading||taskStatus===1"
                    @click.stop="onClickClear"
                    v-show="data.length>0">清空</Button>
            <Button type="primary"
                    class="form-btn"
                    :disabled="loading||taskStatus===1||disabled"
                    @click.stop="disabled?'':activateGoods()"
                    v-show="data.length>0">确认</Button>
          </li>
        </ul>
        <div class="table">
          <div class="plan">
            <template v-if="taskStatus">
              <div class="plan-title">
                <span style="color:#0652DD">{{finishYardsNumber}}</span>
                <span>/{{totalYardsNumber}}</span>
              </div>
              <div class="plan-con">
                <span>激活进度</span>
                <Progress :percent="getProductPlan"
                          :stroke-width="14"
                          style="width:320px" />
              </div>
            </template>
          </div>
          <Table :height="tableHeight"
                 :columns="columns"
                 :data="data"></Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
    <SelectStorageModal v-model="selectStorageVisible"
                        @on-click-confirm="getSelectStorage"
                        @on-click-cancle="cancleSelectStorage"></SelectStorageModal>
    <SelectDealerModal v-model="selectDealerVisible"
                       @on-click-confirm="getSelectDealer"
                       @on-click-cancle="cancleSelectDealer"></SelectDealerModal>
    <SelectStorekeeperModal v-model="selectStoragekeeperVisible"
                            @on-click-confirm="getSelectStoragekeeper"
                            @on-click-cancle="cancleSelectStoragekeeper"></SelectStorekeeperModal>
  </div>
</template>

<script>
import { getImportYardsList } from '../../../services/yardsapi';
import { activateGoods, getProductTaskInfo } from '../../../services/activateapi';
import { getNowFormatDay } from '../../../utils/utils';
import ImportTxt from '../../../components/common/ImportTxt';
import SelectStorageModal from '../../../components/product/SelectStorageModal';
import SelectDealerModal from '../../../components/product/SelectDealerModal';
import SelectStorekeeperModal from '../../../components/product/SelectStorekeeperModal';
export default {
  components: { ImportTxt, SelectStorageModal, SelectDealerModal, SelectStorekeeperModal },
  data () {
    return {
      store: 0,
      storeName: '',
      admin: 0,
      adminName: '',
      dealer: 0,
      dealerName: '',
      date: '',
      number: 0,
      loading: false,
      importArr: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          title: '序列号',
          width: 180,
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '商品名称',
          key: 'goodsName',
          tooltip: true,
          width: 160
        },
        {
          title: '农药名称',
          key: 'genericName',
          tooltip: true,
          width: 160
        },
        {
          title: '农药类别',
          key: 'childTypeName',
          tooltip: true,
          width: 160
        },
        {
          title: '规格',
          key: 'specDesc',
          tooltip: true,
          width: 160
        },
        {
          title: '二维码数据',
          key: 'qrcode',
          tooltip: true,
          minWidth: 300
        }
      ],
      data: [],
      selectStorageVisible: false,
      selectDealerVisible: false,
      selectStoragekeeperVisible: false,
      taskId: 0,
      taskTimer: null,
      finishYardsNumber: 0,
      totalYardsNumber: 0,
      taskStatus: 0,
      disabled: false
    }
  },
  computed: {
    getProductPlan () {
      if (this.totalYardsNumber) {
        return parseInt(this.finishYardsNumber / this.totalYardsNumber * 100)
      } else {
        return 100
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 360
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 360
      }
      this.date = getNowFormatDay()
    },
    onChangeDate (date) {
      this.date = date
    },
    // 选择仓库
    selectStorage () {
      this.$once('selectStorage', query => {
        this.store = query.storageId
        this.storeName = query.name
      })
      this.selectStorageVisible = true
    },
    // 获取选择的仓库
    getSelectStorage (obj) {
      this.$emit('selectStorage', obj)
    },
    // 取消选择仓库
    cancleSelectStorage () {
      this.$off('selectStorage')
    },
    // 选择单位
    selectDealer () {
      this.$once('selectDealer', query => {
        this.dealer = query.dealerId
        this.dealerName = query.unitName
      })
      this.selectDealerVisible = true
    },
    // 获取选择的单位
    getSelectDealer (obj) {
      this.$emit('selectDealer', obj)
    },
    // 取消选择单位
    cancleSelectDealer () {
      this.$off('selectDealer')
    },
    // 选择库管
    selectStoragekeeper () {
      this.$once('selectStoragekeeper', query => {
        this.admin = query.manageId
        this.adminName = query.manageName
      })
      this.selectStoragekeeperVisible = true
    },
    // 获取选择的库管
    getSelectStoragekeeper (obj) {
      this.$emit('selectStoragekeeper', obj)
    },
    // 取消选择库管
    cancleSelectStoragekeeper () {
      this.$off('selectStoragekeeper')
    },
    importStart () {
      this.loading = true
    },
    importSuccess (arr) {
      this.loading = false
      if (arr.length === 0) return this.$Message.info('请上传有效的数据包')
      this.$Message.info('导入成功')
      this.clearData()
      this.importArr = arr
      this.pageNum = 1
      this.totalPage = this.importArr.length
      this.getImportYardsList()
    },
    importError () {
      this.loading = false
      this.$Message.info('导入数据包失败')
    },
    getImportYardsList () {
      if (this.importArr.length === 0) return
      let arr = this.importArr
      let pageNum = this.pageNum
      let pageSize = this.pageSize
      let startIndex = (pageNum - 1) * pageSize
      let endIndex = pageNum * pageSize
      let newArr = arr.slice(startIndex, endIndex)
      let params = {
        codeList: newArr.join()
      }
      getImportYardsList(params).then(res => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },
    // 点击清空数据
    onClickClear () {
      this.$Modal.confirm({
        title: '清空数据',
        content: '<p>是否清空数据？</p>',
        onOk: () => {
          this.clearData()
        }
      })
    },
    // 清空数据
    clearData () {
      this.date = getNowFormatDay()
      this.number = 0
      this.importArr = []
      this.data = []
      this.pageNum = 1
      this.totalPage = 0
      this.taskStatus = 0
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getImportYardsList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getImportYardsList()
    },
    // 激活
    activateGoods () {
      if (!this.store) return this.$Message.info('请选择仓库')
      if (!this.dealer) return this.$Message.info('请选择收货单位')
      if (!this.admin) return this.$Message.info('请选择库管员')
      if (!this.date) return this.$Message.info('请选择激活时间')
      let params = {
        traceCode: this.importArr,
        activeTime: this.date,
        storageId: this.store,
        manageId: this.admin,
        dealerId: this.dealer
      }
      activateGoods(2, params).then(res => {
        if (res.code === 0) {
          this.taskId = res.data.taskId
          this.createProductTask()
        } else {
          this.disabled = false
          if (res.msg) this.$Message.info(res.msg)
        }
      }).catch(err => {
        this.disabled = false
      })
    },
    // 创建生产任务
    createProductTask () {
      if (!this.taskId) return
      this.taskStatus = 0
      clearInterval(this.taskTimer)
      this.taskTimer = setInterval(() => {
        getProductTaskInfo(this.taskId).then(res => {
          this.disabled = false
          if (res.code === 0) {
            this.finishYardsNumber = res.data.finishQty
            this.totalYardsNumber = res.data.genQty - res.data.failQty
            this.number = res.data.finishQty
            this.taskStatus = res.data.genStatus
            if (res.data.genStatus === 2) {
              clearInterval(this.taskTimer)
              this.taskTimer = null
            }
          } else {
            if (res.msg) this.$Message.info(res.msg)
            clearInterval(this.taskTimer)
            this.taskTimer = null
          }
        }).catch(err => {
          this.disabled = false
          clearInterval(this.taskTimer)
          this.taskTimer = null
        })
      }, 1000)
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #e6e6f2;
  .title {
    font-size: 22px;
    line-height: 66px;
    text-align: center;
    background-color: #fff;
  }
  .content {
    padding: 20px;
    .form {
      padding: 0 10px 10px;
      background-color: #314a76;
      .form-ul {
        padding: 0 10px;
        .form-li {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .form-item {
            display: flex;
            align-items: center;
            margin-right: 70px;
            padding: 15px 0;
            .form-key {
              font-size: 16px;
              color: #fff;
              flex-shrink: 0;
              margin-right: 10px;
              span:first-child {
                width: 8px;
                display: inline-block;
              }
            }
            .form-value {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #fff;
              position: relative;
              ::v-deep {
                .ivu-input {
                  font-size: 16px;
                  line-height: 32px;
                  background-color: transparent;
                  border: none;
                  color: #fff;
                  &:focus {
                    border: none;
                    box-shadow: none;
                  }
                }
                .ivu-select-selection {
                  background-color: transparent;
                  border: none;
                  color: #fff;
                  span {
                    font-size: 16px;
                    line-height: 32px;
                  }
                  &:focus {
                    border: none;
                    box-shadow: none;
                  }
                }
                .form-span {
                  font-size: 14px;
                  padding: 0 4px;
                  background-color: #314a76;
                  color: #fff;
                  position: absolute;
                  right: 0;
                  cursor: pointer;
                }
              }
              .big ::v-deep input {
                font-size: 26px;
              }
            }
          }
          .form-btn {
            margin-right: 25px;
          }
          ::v-deep .ivu-btn {
            line-height: 2;
          }
        }
      }
      .table {
        background-color: #fff;
        padding-bottom: 10px;
        .plan {
          padding-top: 10px;
          padding-right: 10px;
          text-align: right;
          .plan-title {
            font-size: 14px;
            padding-right: 30px;
          }
          .plan-con {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
              font-size: 14px;
              line-height: 20px;
              color: #666;
            }
          }
        }
      }
    }
  }
}
</style>
