import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 获取经销商列表
 */
export const getDealerList = (params) => get('/tradeapp/trace/dealer/list', params)

/**
 * 获取经销商详情
 */
export const getDealerDetail = (id) => get('/tradeapp/trace/dealer/' + id)

/**
 * 批量删除经销商
 */
export const deleteDealer = (params) => del('/tradeapp/trace/dealer', params)

/**
 * 新建经销商
 */
export const createDealer = (params) => post('/tradeapp/trace/dealer', params)

/**
 * 修改经销商
 */
export const editDealer = (id, params) => patch('/tradeapp/trace/dealer/' + id, params)

/**
 * 获取全部经销商列表
 */
export const getAllDealerList = () => get('/tradeapp/trace/dealer/ent/list')